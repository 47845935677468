"use strict";

// For Footer //
///////////////////////////////////////////////////////////
// Set current year
const yearEl = document.querySelector(".footer__year");
const currentYear = new Date().getFullYear();
yearEl.textContent = currentYear;

///////////////////////////////////////////////////////////
// Make mobile navigation work

const btnNavEl = document.querySelector(".btn-mobile-nav");
const headerEl = document.querySelector(".header__nav");
const headerListEl = document.querySelector(".header__main__nav");

btnNavEl.addEventListener("click", function () {
  headerEl.classList.toggle("nav-open");
  headerListEl.classList.toggle("nav-open");
});

///////////////////////////////////////////////////////////
// Sticky navigation

const sectionHeaderEl = document.querySelector(".header");

const obs = new IntersectionObserver(
  function (entries) {
    const ent = entries[0];
    console.log(ent);

    if (ent.isIntersecting === false) {
      document.body.classList.add("sticky");
    }

    if (ent.isIntersecting === true) {
      document.body.classList.remove("sticky");
    }
  },
  {
    // In the viewport
    root: null,
    threshold: 0,
    rootMargin: "0px",
  }
);
obs.observe(sectionHeaderEl);

///////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////
// Smooth scrolling animation

const allLinks = document.querySelectorAll("a");

allLinks.forEach(function (link) {
  link.addEventListener("click", function (e) {
    // Close mobile navigation
    if (headerEl.classList.contains("nav-open")) {
      headerEl.classList.remove("nav-open");
      headerListEl.classList.remove("nav-open");
    }

    const href = link.getAttribute("href");
    if (href === "#" || href === "") {
      e.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else if (href.startsWith("#")) {
      e.preventDefault();
      const sectionEl = document.querySelector(href);
      sectionEl.scrollIntoView({ behavior: "smooth" });
    }
  });
});
